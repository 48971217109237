import React from "react";
import {
  PageContent,
  Paragraph,
  Heading,
  Carousel,
  CarouselProps,
} from "grommet";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import Page from "../components/page";
import SEO from "../components/seo";

const SlideShow = ({ ...props }: CarouselProps) => {
  return (
    <Carousel play={3000} {...props}>
      <StaticImage
        objectFit="cover"
        src="../images/carriere.jpg"
        alt="La carriere des écuries du cerf"
      />
      <StaticImage
        objectFit="cover"
        src="../images/manege.jpg"
        alt="Le manege des écuries du cerf"
      />
      <StaticImage
        objectFit="cover"
        src="../images/rondDeMarche.jpg"
        alt="Le rond de marche des écuries du cerf"
      />
      <StaticImage
        objectFit="cover"
        src="../images/box.jpg"
        alt="Les box des écuries du cerf"
      />
    </Carousel>
  );
};

const IndexPage = () => {
  return (
    <Page kind="narrow">
      <SEO
        title="Les écuries du cerf | pension pour votre cheval"
        description="Les écuries du cerf, écuries de propriétaires, pensions pour chevaux à Gaudreville-la-Rivière entre Evreux et Conches-en-Ouche dans l'eure 27"
      />
      <PageContent>
        <Heading textAlign="center" alignSelf="center">
          Ecuries de propriétaires
        </Heading>
        <Paragraph
          fill
          textAlign="center"
          style={{ textDecoration: "underline" }}
        >
          Les écuries du Cerf vous accueillent tout au long de l'année en{" "}
          <Link to="/pension">pension</Link> pré/box.
        </Paragraph>
        <SlideShow />
        <Paragraph fill>
          A Gaudreville-la-Rivière entre Evreux et Conches-en-Ouche dans l'Eure
          (27), vous y trouverez les écuries du Cerf, écuries familiales créées
          pour le bien être de votre cheval.
        </Paragraph>
        <Paragraph fill>
          Nos écuries répondent aux besoins quotidiens de votre équidé, dans un
          cadre calme et verdoyant, au pied de la forêt de
          Gaudreville-la-Rivière.
        </Paragraph>
      </PageContent>
    </Page>
  );
};

export default IndexPage;
